import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router';
import {
    Navbar,
    Nav,
    NavItem,
    NavLink,
    NavbarBrand,
    Container,
    Row,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import phrlogo from '../assets/phr_logo.png';
import pellalogo from '../assets/pella_logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faExclamationSquare,
    faSync,
    faImage,
} from '@fortawesome/pro-solid-svg-icons';
import Product from './Product';
import '../styles/Home.css';

export default function Home(props) {
    let { id } = useParams();

    const [data, setData] = useState([]);
    const [isErrored, setIsErrored] = useState(false);
    const [reloadForce, setReloadForce] = useState(false);
    const [showPhotoImages, setShowPhotoImages] = useState(false);
    const [websiteTitle, setWebsiteTitle] = useState('');
    const [isPellaDomain, setIsPellaDomain] = useState(false);
    const [isPowerDomain, setIsPowerDomain] = useState(false);
    const [customerLocations, setCustomerLocations] = useState([]);
    const [location, setLocation] = useState();


    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const populateData = useCallback(async () => {
        console.log('Fetching data');
        if (!location) return; // Guard clause to exit early if location is not set

        let hostname = window.location.hostname;
        let queryPath = `ProductLeadTime/${location.customerNum}/${location.shipToAreaCode}`;
        sessionStorage.setItem('location', JSON.stringify(location))

        try {
            const responseData = await fetch(queryPath).then(res => res.json());
            setData(responseData);
        } catch (error) {
            console.error(error);
            setIsErrored(true);
        }

    }, [location]);


    const determineInitialLocation = (fetchedCustomerLocations) => {
        const savedLocation = sessionStorage.getItem('location');
        console.log(savedLocation)

        // Attempt to restore the location from sessionStorage first
        if (savedLocation) {
            setLocation(JSON.parse(savedLocation));
        } else if (fetchedCustomerLocations && fetchedCustomerLocations.length > 0) {
            // No saved location, so set it to the first item of the fetched customerLocations
            const initialLocation = fetchedCustomerLocations[0];
            setLocation(initialLocation);
            sessionStorage.setItem('location', JSON.stringify(initialLocation)); // Save the initial location for future
        }
    };

    useEffect(() => {
        if (id === 'photos') setShowPhotoImages(true);
        else setShowPhotoImages(false);
    }, [id]);

    useEffect(() => {
        if (reloadForce) {
            window.sessionStorage.setItem('lastForcedReload', new Date().getTime());

            navigator.serviceWorker.ready.then((registration) => {
                registration.update();
            });
            window.location.reload(true);

        }
    }, [reloadForce]);

    useEffect(() => {
        populateData();

        const interval = setInterval(() => {
            populateData();
        }, 900000);
        return () => clearInterval(interval);
    }, [populateData]);

    useEffect(() => {
        const savedHostname = sessionStorage.getItem('savedHostname');
        const hostname = window.location.hostname;

        if (!savedHostname) {
            sessionStorage.setItem('savedHostname', hostname);
        } else if (savedHostname !== hostname) {
            sessionStorage.removeItem('location'); 
            sessionStorage.setItem('savedHostname', hostname); 
        }
        let customerLocationsQuery;
        if (
            hostname === 'pellacoastalvinyl.com' ||
            hostname === 'www.pellacoastalvinyl.com'
        ) {
            setWebsiteTitle('Pella Coastal Vinyl');
            setIsPellaDomain(true);
            setIsPowerDomain(false);
            customerLocationsQuery = 'ProductLeadTime/CustomerLocations/Pella'
        } else {
            setWebsiteTitle('Fortress Lead Time Dashboard');
            setIsPellaDomain(false);
            setIsPowerDomain(true);
            customerLocationsQuery = 'ProductLeadTime/CustomerLocations/Power'

        };

        const fetchCustomerLocations = async () => {
            try {
                const response = await fetch(customerLocationsQuery);
                const data = await response.json();
                setCustomerLocations(data);
                determineInitialLocation(data);
            } catch (error) {
                console.error(error);
                setIsErrored(true);
            }
        };
        fetchCustomerLocations();
    }, []);

    return (
        <>
            <Navbar color="dark" dark expand="md" className="mb-2">
                <NavbarBrand href="/">
                    {isPellaDomain && (
                        <img
                            width="50px"
                            height="50px"
                            src={pellalogo}
                            alt="Logo"
                            className="d-inline-block mr-2"
                        />
                    )}
                    {isPowerDomain && (
                        <img
                            width="50px"
                            height="50px"
                            src={phrlogo}
                            alt="Logo"
                            className="d-inline-block mr-2"
                        />
                    )}
                    {websiteTitle}
                </NavbarBrand>
                <Nav className="ml-auto" navbar>
                    {
                        isPowerDomain && (
                            <Dropdown isOpen={dropdownOpen} toggle={toggle} {...props}>
                                <DropdownToggle caret size="lg" color="dark">
                                    {location && location.pellaAPIRegionName}
                                </DropdownToggle>
                                <DropdownMenu>
                                    {
                                        customerLocations.map((customerLocation) => (
                                            <DropdownItem onClick={() => setLocation(customerLocation)} key={customerLocation.customerNum}>{customerLocation.pellaAPIRegionName}</DropdownItem>
                                        ))
                                    }
                                </DropdownMenu>

                            </Dropdown>
                        )
                    }

                    <NavItem className="navbar-right">
                        <NavLink
                            onClick={() => setShowPhotoImages(!showPhotoImages)}
                        >
                            <FontAwesomeIcon
                                icon={faImage}
                                size="2x"
                                color="white"
                                className="fa-pic"
                            />
                        </NavLink>
                    </NavItem>
                    <NavItem className="navbar-right">
                        <NavLink onClick={() => setReloadForce(true)}>
                            <FontAwesomeIcon
                                icon={faSync}
                                size="2x"
                                color="white"
                                className="fa-refresh"
                            />
                        </NavLink>
                    </NavItem>
                    {isErrored && (
                        <NavItem className="navbar-right">
                            <FontAwesomeIcon
                                icon={faExclamationSquare}
                                size="3x"
                                color="red"
                            />
                        </NavItem>
                    )}
                </Nav>
            </Navbar>
            <main role="main">
                <Container fluid>
                    <Row>
                        {data.map((product) => (
                            <Product
                                key={product.series}
                                product={product}
                                isPhotoImage={showPhotoImages}
                            />
                        ))}
                    </Row>
                </Container>
            </main>
        </>
    );
}
